import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from "../utils"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <NotFoundWrapper>
        <h1>NOT FOUND</h1>
        <p>Toutes mes excuses, cette page n'existe pas...</p>
      </NotFoundWrapper>
    </Section>
  </Layout>
)

export default NotFoundPage

const NotFoundWrapper = styled.div`
  height: calc(100vh - 30rem);
  @media screen and (max-width: 768px) {
    height: calc(100vh - 34rem);
  }
`
